import { createApp } from "vue";
import i18n from '@/i18n'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/styles/common.css'
import '@/assets/styles/selectric.scss'

const app = createApp(App)
app.use(store).use(router).use(i18n).mount("#app");
