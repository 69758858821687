import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
  },
  {
    path: "/about/team",
    name: "about-team",
    component: () =>
      import(/* webpackChunkName: "about-team" */ "../views/about-team.vue"),
  },
  {
    path: "/about/team/robert-f-smith",
    name: "robert-f-smith",
    component: () =>
      import(/* webpackChunkName: "robert-f-smith" */ "../views/about/team/robert-f-smith.vue"),
  },
  {
    path: "/about/team/david-a-breach",
    name: "david-a-breach",
    component: () =>
      import(/* webpackChunkName: "david-a-breach" */ "../views/about/team/david-a-breach.vue"),
  },
  {
    path: "/about/team/rachel-arnold",
    name: "rachel-arnold",
    component: () =>
      import(/* webpackChunkName: "rachel-arnold" */ "../views/about/team/rachel-arnold.vue"),
  },
  {
    path: "/about/team/lauren-dillard",
    name: "lauren-dillard",
    component: () =>
      import(/* webpackChunkName: "lauren-dillard" */ "../views/about/team/lauren-dillard.vue"),
  },
  {
    path: "/about/team/david-flannery",
    name: "david-flannery",
    component: () =>
      import(/* webpackChunkName: "david-flannery" */ "../views/about/team/david-flannery.vue"),
  },
  {
    path: "/about/team/michael-fosnaugh",
    name: "michael-fosnaugh",
    component: () =>
      import(/* webpackChunkName: "michael-fosnaugh" */ "../views/about/team/michael-fosnaugh.vue"),
  },
  {
    path: "/about/team/jim-hickey",
    name: "jim-hickey",
    component: () =>
      import(/* webpackChunkName: "jim-hickey" */ "../views/about/team/jim-hickey.vue"),
  },
  {
    path: "/about/team/justin-hirsch",
    name: "justin-hirsch",
    component: () =>
      import(/* webpackChunkName: "justin-hirsch" */ "../views/about/team/justin-hirsch.vue"),
  },
  {
    path: "/about/team/betty-hung",
    name: "betty-hung",
    component: () =>
      import(/* webpackChunkName: "betty-hung" */ "../views/about/team/betty-hung.vue"),
  },
  {
    path: "/about/team/greg-myers",
    name: "greg-myers",
    component: () =>
      import(/* webpackChunkName: "greg-myers" */ "../views/about/team/greg-myers.vue"),
  },
  {
    path: "/about/team/monti-saroya",
    name: "monti-saroya",
    component: () =>
      import(/* webpackChunkName: "monti-saroya" */ "../views/about/team/monti-saroya.vue"),
  },
  {
    path: "/about/team/patrick-severson",
    name: "patrick-severson",
    component: () =>
      import(/* webpackChunkName: "patrick-severson" */ "../views/about/team/patrick-severson.vue"),
  },
  {
    path: "/about/team/nadeem-syed",
    name: "nadeem-syed",
    component: () =>
      import(/* webpackChunkName: "nadeem-syed" */ "../views/about/team/nadeem-syed.vue"),
  },
  {
    path: "/about/team/martin-taylor",
    name: "martin-taylor",
    component: () =>
      import(/* webpackChunkName: "martin-taylor" */ "../views/about/team/martin-taylor.vue"),
  },
  {
    path: "/about/team/clifford-chiu",
    name: "clifford-chiu",
    component: () =>
      import(/* webpackChunkName: "clifford-chiu" */ "../views/about/team/clifford-chiu.vue"),
  },
  {
    path: "/companies",
    name: "companies",
    component: () =>
      import(/* webpackChunkName: "companies" */ "@/views/companies/index.vue"),
  },
  {
    path: "/companies/portfolio",
    name: "portfolio",
    component: () =>
      import(/* webpackChunkName: "portfolio" */ "@/views/companies/portfolio.vue"),
  },
  {
    path: "/companies/approach",
    name: "approach",
    component: () =>
      import(/* webpackChunkName: "approach" */ "@/views/companies/approach.vue"),
  },
  {
    path: "/strategies",
    name: "strategies",
    component: () =>
      import(/* webpackChunkName: "strategies" */ "@/views/strategies/index.vue"),
  },
  {
    path: "/strategies/private-equity",
    name: "private-equity",
    component: () =>
      import(/* webpackChunkName: "private-equity" */ "@/views/strategies/private-equity.vue"),
  },
  {
    path: "/strategies/permanent-capital",
    name: "permanent-capital",
    component: () =>
      import(/* webpackChunkName: "permanent-capital" */ "@/views/strategies/permanent-capital.vue"),
  },
  {
    path: "/strategies/credit",
    name: "credit",
    component: () =>
      import(/* webpackChunkName: "credit" */ "@/views/strategies/credit.vue"),
  },
  {
    path: "/strategies/credit/founderdirect",
    name: "founderdirect",
    component: () =>
      import(/* webpackChunkName: "founderdirect" */ "@/views/strategies/founderdirect.vue"),
  },
  {
    path: "/strategies/public-equity",
    name: "public-equity",
    component: () =>
      import(/* webpackChunkName: "public-equity" */ "@/views/strategies/public-equity.vue"),
  },
  {
    path: "/responsibility",
    name: "responsibility",
    component: () =>
      import(/* webpackChunkName: "responsibility" */ "@/views/responsibility/index.vue"),
  },
  {
    path: "/responsibility/environmental-practices",
    name: "environmental-practices",
    component: () =>
      import(/* webpackChunkName: "environmental-practices" */ "@/views/responsibility/environmental-practices.vue"),
  },
  {
    path: "/responsibility/social-engagement",
    name: "social-engagement",
    component: () =>
      import(/* webpackChunkName: "social-engagement" */ "@/views/responsibility/social-engagement.vue"),
  },
  {
    path: "/responsibility/governance-strategy",
    name: "governance-strategy",
    component: () =>
      import(/* webpackChunkName: "governance-strategy" */ "@/views/responsibility/governance-strategy.vue"),
  },
  {
    path: "/media_library/vista-equity-partners-names-winners-of-7th-annual-global-hackathon",
    name: "vista-equity-partners-names-winners-of-7th-annual-global-hackathon",
    component: () =>
      import(/* webpackChunkName: "vista-equity-partners-names-winners-of-7th-annual-global-hackathon" */ "@/views/media_library/vista-equity-partners-names-winners-of-7th-annual-global-hackathon.vue"),
  },
  {
    path: "/media_library/vista-equity-partners-appoints-dan-parant-as-global-head-of-private-wealth-solutions-to-expand-access-to-private-market-technology-investments",
    name: "vista-equity-partners-appoints-dan-parant-as-global-head-of-private-wealth-solutions-to-expand-access-to-private-market-technology-investments",
    component: () =>
      import(/* webpackChunkName: "vista-equity-partners-appoints-dan-parant-as-global-head-of-private-wealth-solutions-to-expand-access-to-private-market-technology-investments" */ "@/views/media_library/vista-equity-partners-appoints-dan-parant-as-global-head-of-private-wealth-solutions-to-expand-access-to-private-market-technology-investments.vue"),
  },
  {
    path: "/media_library/vista-equity-partners-announces-2023-senior-leadership-promotions",
    name: "vista-equity-partners-announces-2023-senior-leadership-promotions",
    component: () =>
      import(/* webpackChunkName: "vista-equity-partners-announces-2023-senior-leadership-promotions" */ "@/views/media_library/vista-equity-partners-announces-2023-senior-leadership-promotions.vue"),
  },
  {
    path: "/media_library/vista-equity-partners-and-vista-credit-partners-named-to-inc-s-list-of-founder-friendly-investors",
    name: "vista-equity-partners-and-vista-credit-partners-named-to-inc-s-list-of-founder-friendly-investors",
    component: () =>
      import(/* webpackChunkName: "vista-equity-partners-and-vista-credit-partners-named-to-inc-s-list-of-founder-friendly-investors" */ "@/views/media_library/vista-equity-partners-and-vista-credit-partners-named-to-inc-s-list-of-founder-friendly-investors.vue"),
  },
  {
    path: "/media_library/vista-equity-partners-and-vista-credit-partners-named-to-inc-s-list-of-founder-friendly-investors",
    name: "vista-equity-partners-and-vista-credit-partners-named-to-inc-s-list-of-founder-friendly-investors",
    component: () =>
      import(/* webpackChunkName: "vista-equity-partners-and-vista-credit-partners-named-to-inc-s-list-of-founder-friendly-investors" */ "@/views/media_library/vista-equity-partners-and-vista-credit-partners-named-to-inc-s-list-of-founder-friendly-investors.vue"),
  },
  {
    path: "/insights",
    name: "insights",
    component: () =>
      import(/* webpackChunkName: "insights" */ "@/views/insights.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/contact.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/news.vue"),
  },
  {
    path: "/news/press-releases",
    name: "press-releases",
    component: () =>
      import(/* webpackChunkName: "press-releases" */ "@/views/news/press-releases.vue"),
  },
  {
    path: "/news/news-updates",
    name: "news-updates",
    component: () =>
      import(/* webpackChunkName: "news-updates" */ "@/views/news/news-updates.vue"),
  },
  {
    path: "/careers",
    name: "careers",
    component: () =>
      import(/* webpackChunkName: "careers" */ "@/views/careers/index.vue"),
  },
  {
    path: "/careers/our-people",
    name: "our-people",
    component: () =>
      import(/* webpackChunkName: "our-people" */ "@/views/careers/our-people.vue"),
  },
  {
    path: "/careers/open-positions",
    name: "open-positions",
    component: () =>
      import(/* webpackChunkName: "open-positions" */ "@/views/careers/open-positions.vue"),
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () =>
      import(/* webpackChunkName: "terms-conditions" */ "@/views/terms-conditions.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "@/views/privacy.vue"),
  },
  {
    path: "/disclosures",
    name: "disclosures",
    component: () =>
      import(/* webpackChunkName: "disclosures" */ "@/views/disclosures.vue"),
  },
  {
    path: "/onevista",
    name: "onevista",
    component: () =>
      import(/* webpackChunkName: "onevista" */ "@/views/onevista.vue"),
  },
  {
    path: "/responsible-investment-policy",
    name: "responsible-investment-policy",
    component: () =>
      import(/* webpackChunkName: "responsible-investment-policy" */ "@/views/responsible-investment-policy.vue"),
  },
  {
    path: "/faqs",
    name: "faqs",
    component: () =>
      import(/* webpackChunkName: "faqs" */ "@/views/faqs.vue"),
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    component: () =>
      import(/* webpackChunkName: "cookie-policy" */ "@/views/cookie-policy.vue"),
  },
  {
    path: "/manage-cookie-preferences",
    name: "manage-cookie-preferences",
    component: () =>
      import(/* webpackChunkName: "manage-cookie-preferences" */ "@/views/manage-cookie-preferences.vue"),
  },
  {
    path: "/portfolio/37-5",
    name: "portfolio/37-5",
    component: () =>
      import(/* webpackChunkName: "portfolio/37-5" */ "@/views/portfolio/37-5.vue"),
  },
  {
    path: "/portfolio/3pillar-global",
    name: "portfolio/3pillar-global",
    component: () =>
      import(/* webpackChunkName: "portfolio/3pillar-global" */ "@/views/portfolio/3pillar-global.vue"),
  },
  {
    path: "/portfolio/7s-personal",
    name: "portfolio/7s-personal",
    component: () =>
      import(/* webpackChunkName: "portfolio/7s-personal" */ "@/views/portfolio/7s-personal.vue"),
  },
  {
    path: "/portfolio/a10-capital",
    name: "portfolio/a10-capital",
    component: () =>
      import(/* webpackChunkName: "portfolio/a10-capital" */ "@/views/portfolio/a10-capital.vue"),
  },
  {
    path: "/portfolio/accounting-seed",
    name: "portfolio/accounting-seed",
    component: () =>
      import(/* webpackChunkName: "portfolio/accounting-seed" */ "@/views/portfolio/accounting-seed.vue"),
  },
  {
    path: "/portfolio/accupac",
    name: "portfolio/accupac",
    component: () =>
      import(/* webpackChunkName: "portfolio/accupac" */ "@/views/portfolio/accupac.vue"),
  },
  {
    path: "/portfolio/acg-materials",
    name: "portfolio/acg-materials",
    component: () =>
      import(/* webpackChunkName: "portfolio/acg-materials" */ "@/views/portfolio/acg-materials.vue"),
  },
  {
    path: "/portfolio/acqua-sapone",
    name: "portfolio/acqua-sapone",
    component: () =>
      import(/* webpackChunkName: "portfolio/acqua-sapone" */ "@/views/portfolio/acqua-sapone.vue"),
  },
  {
    path: "/portfolio/adscape-media",
    name: "portfolio/adscape-media",
    component: () =>
      import(/* webpackChunkName: "portfolio/adscape-media" */ "@/views/portfolio/adscape-media.vue"),
  },
  {
    path: "/portfolio/adtheorent",
    name: "portfolio/adtheorent",
    component: () =>
      import(/* webpackChunkName: "portfolio/adtheorent" */ "@/views/portfolio/adtheorent.vue"),
  },
  {
    path: "/portfolio/advanced-dental-brands",
    name: "portfolio/advanced-dental-brands",
    component: () =>
      import(/* webpackChunkName: "portfolio/advanced-dental-brands" */ "@/views/portfolio/advanced-dental-brands.vue"),
  },
  {
    path: "/portfolio/aert",
    name: "portfolio/aert",
    component: () =>
      import(/* webpackChunkName: "portfolio/aert" */ "@/views/portfolio/aert.vue"),
  },
  {
    path: "/portfolio/agere-systems",
    name: "portfolio/agere-systems",
    component: () =>
      import(/* webpackChunkName: "portfolio/agere-systems" */ "@/views/portfolio/agere-systems.vue"),
  },
  {
    path: "/portfolio/aircom",
    name: "portfolio/aircom",
    component: () =>
      import(/* webpackChunkName: "portfolio/aircom" */ "@/views/portfolio/aircom.vue"),
  },
  {
    path: "/portfolio/albertville-quality-foods",
    name: "portfolio/albertville-quality-foods",
    component: () =>
      import(/* webpackChunkName: "portfolio/albertville-quality-foods" */ "@/views/portfolio/albertville-quality-foods.vue"),
  },
  {
    path: "/portfolio/alder-biopharmaceuticals",
    name: "portfolio/alder-biopharmaceuticals",
    component: () =>
      import(/* webpackChunkName: "portfolio/alder-biopharmaceuticals" */ "@/views/portfolio/alder-biopharmaceuticals.vue"),
  },
  {
    path: "/portfolio/align-networks",
    name: "portfolio/align-networks",
    component: () =>
      import(/* webpackChunkName: "portfolio/align-networks" */ "@/views/portfolio/align-networks.vue"),
  },
  {
    path: "/portfolio/allegient-systems",
    name: "portfolio/allegient-systems",
    component: () =>
      import(/* webpackChunkName: "portfolio/allegient-systems" */ "@/views/portfolio/allegient-systems.vue"),
  },
  {
    path: "/portfolio/alteo",
    name: "portfolio/alteo",
    component: () =>
      import(/* webpackChunkName: "portfolio/alteo" */ "@/views/portfolio/alteo.vue"),
  },
  {
    path: "/portfolio/american-hardwood-industries",
    name: "portfolio/american-hardwood-industries",
    component: () =>
      import(/* webpackChunkName: "portfolio/american-hardwood-industries" */ "@/views/portfolio/american-hardwood-industries.vue"),
  },
  {
    path: "/portfolio/american-vision-partners",
    name: "portfolio/american-vision-partners",
    component: () =>
      import(/* webpackChunkName: "portfolio/american-vision-partners" */ "@/views/portfolio/american-vision-partners.vue"),
  },
  {
    path: "/portfolio/all-american-group",
    name: "all-american-group",
    component: () =>
      import(/* webpackChunkName: "portfolio/all-american-group" */ "@/views/portfolio/all-american-group.vue"),
  },
  {
    path: "/portfolio/ampac",
    name: "ampac",
    component: () =>
      import(/* webpackChunkName: "portfolio/ampac" */ "@/views/portfolio/ampac.vue"),
  },
  {
    path: "/portfolio/amsive",
    name: "amsive",
    component: () =>
      import(/* webpackChunkName: "portfolio/amsive" */ "@/views/portfolio/amsive.vue"),
  },
  {
    path: "/portfolio/andwis",
    name: "andwis",
    component: () =>
      import(/* webpackChunkName: "portfolio/andwis" */ "@/views/portfolio/andwis.vue"),
  },
  {
    path: "/portfolio/anvis-group",
    name: "anvis-group",
    component: () =>
      import(/* webpackChunkName: "portfolio/anvis-group" */ "@/views/portfolio/anvis-group.vue"),
  },
  {
    path: "/portfolio/aps-healthcare",
    name: "aps-healthcare",
    component: () =>
      import(/* webpackChunkName: "portfolio/aps-healthcare" */ "@/views/portfolio/aps-healthcare.vue"),
  },
  {
    path: "/portfolio/apollo-endosurgery",
    name: "apollo-endosurgery",
    component: () =>
      import(/* webpackChunkName: "portfolio/apollo-endosurgery" */ "@/views/portfolio/apollo-endosurgery.vue"),
  },
  {
    path: "/portfolio/arboc-specialty-vehicles",
    name: "arboc-specialty-vehicles",
    component: () =>
      import(/* webpackChunkName: "portfolio/arboc-specialty-vehicles" */ "@/views/portfolio/arboc-specialty-vehicles.vue"),
  },
  {
    path: "/portfolio/augmedics",
    name: "augmedics",
    component: () =>
      import(/* webpackChunkName: "portfolio/augmedics" */ "@/views/portfolio/augmedics.vue"),
  },
  {
    path: "/portfolio/aspire-pharma",
    name: "aspire-pharma",
    component: () =>
      import(/* webpackChunkName: "portfolio/aspire-pharma" */ "@/views/portfolio/aspire-pharma.vue"),
  },
  {
    path: "/portfolio/beacon-industrial-group",
    name: "beacon-industrial-group",
    component: () =>
      import(/* webpackChunkName: "portfolio/beacon-industrial-group" */ "@/views/portfolio/beacon-industrial-group.vue"),
  },
  {
    path: "/portfolio/avi-spl",
    name: "avi-spl",
    component: () =>
      import(/* webpackChunkName: "portfolio/avi-spl" */ "@/views/portfolio/avi-spl.vue"),
  },
  {
    path: "/portfolio/barton-associates",
    name: "barton-associates",
    component: () =>
      import(/* webpackChunkName: "portfolio/barton-associates" */ "@/views/portfolio/barton-associates.vue"),
  },
  {
    path: "/portfolio/aviapartner",
    name: "aviapartner",
    component: () =>
      import(/* webpackChunkName: "portfolio/aviapartner" */ "@/views/portfolio/aviapartner.vue"),
  },
  {
    path: "/portfolio/ascent-global-logistics",
    name: "ascent-global-logistics",
    component: () =>
      import(/* webpackChunkName: "portfolio/ascent-global-logistics" */ "@/views/portfolio/ascent-global-logistics.vue"),
  },
  {
    path: "/portfolio/arvelle-therapeutics",
    name: "arvelle-therapeutics",
    component: () =>
      import(/* webpackChunkName: "portfolio/arvelle-therapeutics" */ "@/views/portfolio/arvelle-therapeutics.vue"),
  },
  {
    path: "/portfolio/armetallizing",
    name: "armetallizing",
    component: () =>
      import(/* webpackChunkName: "portfolio/armetallizing" */ "@/views/portfolio/armetallizing.vue"),
  },
  {
    path: "/portfolio/arctic-glacier-holdings",
    name: "arctic-glacier-holdings",
    component: () =>
      import(/* webpackChunkName: "portfolio/arctic-glacier-holdings" */ "@/views/portfolio/arctic-glacier-holdings.vue"),
  },
  {
    path: "/portfolio/amerijet-international",
    name: "amerijet-international",
    component: () =>
      import(/* webpackChunkName: "portfolio/amerijet-international" */ "@/views/portfolio/amerijet-international.vue"),
  },
  {
    path: "/portfolio/beco-holding-company-bhc",
    name: "beco-holding-company-bhc",
    component: () =>
      import(/* webpackChunkName: "portfolio/beco-holding-company-bhc" */ "@/views/portfolio/beco-holding-company-bhc.vue"),
  },
  {
    path: "/portfolio/beinbauer-group",
    name: "beinbauer-group",
    component: () =>
      import(/* webpackChunkName: "portfolio/beinbauer-group" */ "@/views/portfolio/beinbauer-group.vue"),
  },
  {
    path: "/portfolio/berardi",
    name: "berardi",
    component: () =>
      import(/* webpackChunkName: "portfolio/berardi" */ "@/views/portfolio/berardi.vue"),
  },
  {
    path: "/portfolio/big-tex",
    name: "big-tex",
    component: () =>
      import(/* webpackChunkName: "portfolio/big-tex" */ "@/views/portfolio/big-tex.vue"),
  },
  {
    path: "/portfolio/biovectra",
    name: "biovectra",
    component: () =>
      import(/* webpackChunkName: "portfolio/biovectra" */ "@/views/portfolio/biovectra.vue"),
  },
  {
    path: "/portfolio/bluum",
    name: "bluum",
    component: () =>
      import(/* webpackChunkName: "portfolio/bluum" */ "@/views/portfolio/bluum.vue"),
  },
  {
    path: "/portfolio/bolt",
    name: "bolt",
    component: () =>
      import(/* webpackChunkName: "portfolio/bolt" */ "@/views/portfolio/bolt.vue"),
  },
  {
    path: "/portfolio/bigsal",
    name: "bigsal",
    component: () =>
      import(/* webpackChunkName: "portfolio/bigsal" */ "@/views/portfolio/bigsal.vue"),
  },
  {
    path: "/portfolio/boostability",
    name: "boostability",
    component: () =>
      import(/* webpackChunkName: "portfolio/boostability" */ "@/views/portfolio/boostability.vue"),
  },
  {
    path: "/portfolio/brand-addition",
    name: "brand-addition",
    component: () =>
      import(/* webpackChunkName: "portfolio/brand-addition" */ "@/views/portfolio/brand-addition.vue"),
  },
  {
    path: "/portfolio/buck",
    name: "buck",
    component: () =>
      import(/* webpackChunkName: "portfolio/buck" */ "@/views/portfolio/buck.vue"),
  },
  {
    path: "/portfolio/cadica",
    name: "cadica",
    component: () =>
      import(/* webpackChunkName: "portfolio/cadica" */ "@/views/portfolio/cadica.vue"),
  },
  // {
  //   path: "/team/sami-mnaymneh",
  //   name: "sami-mnaymneh",
  //   component: () =>
  //     import(/* webpackChunkName: "team/sami-mnaymneh" */ "@/views/team/sami-mnaymneh.vue"),
  // },
  // {
  //   path: "/team/tony-tamer",
  //   name: "tony-tamer",
  //   component: () =>
  //     import(/* webpackChunkName: "team/tony-tamer" */ "@/views/team/tony-tamer.vue"),
  // },
  // {
  //   path: "/team/rick-rosen",
  //   name: "rick-rosen",
  //   component: () =>
  //     import(/* webpackChunkName: "team/rick-rosen" */ "@/views/team/rick-rosen.vue"),
  // },
  // {
  //   path: "/team/brian-schwartz",
  //   name: "brian-schwartz",
  //   component: () =>
  //     import(/* webpackChunkName: "team/brian-schwartz" */ "@/views/team/brian-schwartz.vue"),
  // },
  // {
  //   path: "/team/stuart-aronson",
  //   name: "stuart-aronson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/stuart-aronson" */ "@/views/team/stuart-aronson.vue"),
  // },
  // {
  //   path: "/team/douglas-berman",
  //   name: "douglas-berman",
  //   component: () =>
  //     import(/* webpackChunkName: "team/douglas-berman" */ "@/views/team/douglas-berman.vue"),
  // },
  // {
  //   path: "/team/wolfgang-biedermann",
  //   name: "wolfgang-biedermann",
  //   component: () =>
  //     import(/* webpackChunkName: "team/wolfgang-biedermann" */ "@/views/team/wolfgang-biedermann.vue"),
  // },
  // {
  //   path: "/team/john-bolduc",
  //   name: "john-bolduc",
  //   component: () =>
  //     import(/* webpackChunkName: "team/john-bolduc" */ "@/views/team/john-bolduc.vue"),
  // },
  // {
  //   path: "/team/chad-buresh",
  //   name: "chad-buresh",
  //   component: () =>
  //     import(/* webpackChunkName: "team/chad-buresh" */ "@/views/team/chad-buresh.vue"),
  // },
  // {
  //   path: "/team/jay-maher",
  //   name: "jay-maher",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jay-maher" */ "@/views/team/jay-maher.vue"),
  // },
  // {
  //   path: "/team/jordan-peer-griffin",
  //   name: "jordan-peer-griffin",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jordan-peer-griffin" */ "@/views/team/jordan-peer-griffin.vue"),
  // },
  // {
  //   path: "/team/richard-siegel",
  //   name: "richard-siegel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/richard-siegel" */ "@/views/team/richard-siegel.vue"),
  // },
  // {
  //   path: "/team/rob-wolfson",
  //   name: "rob-wolfson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/rob-wolfson" */ "@/views/team/rob-wolfson.vue"),
  // },
  // {
  //   path: "/team/jackson-craig",
  //   name: "jackson-craig",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jackson-craig" */ "@/views/team/jackson-craig.vue"),
  // },
  // {
  //   path: "/team/riccardo-dallolio",
  //   name: "riccardo-dallolio",
  //   component: () =>
  //     import(/* webpackChunkName: "team/riccardo-dallolio" */ "@/views/team/riccardo-dallolio.vue"),
  // },
  // {
  //   path: "/team/brendan-dolan",
  //   name: "brendan-dolan",
  //   component: () =>
  //     import(/* webpackChunkName: "team/brendan-dolan" */ "@/views/team/brendan-dolan.vue"),
  // },
  // {
  //   path: "/team/javier-casillas",
  //   name: "javier-casillas",
  //   component: () =>
  //     import(/* webpackChunkName: "team/javier-casillas" */ "@/views/team/javier-casillas.vue"),
  // },
  // {
  //   path: "/team/camilo-e-horvilleur",
  //   name: "camilo-e-horvilleur",
  //   component: () =>
  //     import(/* webpackChunkName: "team/camilo-e-horvilleur" */ "@/views/team/camilo-e-horvilleur.vue"),
  // },
  // {
  //   path: "/team/kim-leinwand-erle",
  //   name: "kim-leinwand-erle",
  //   component: () =>
  //     import(/* webpackChunkName: "team/kim-leinwand-erle" */ "@/views/team/kim-leinwand-erle.vue"),
  // },
  // {
  //   path: "/team/pankaj-gupta",
  //   name: "pankaj-gupta",
  //   component: () =>
  //     import(/* webpackChunkName: "team/pankaj-gupta" */ "@/views/team/pankaj-gupta.vue"),
  // },
  // {
  //   path: "/team/ross-hiatt",
  //   name: "ross-hiatt",
  //   component: () =>
  //     import(/* webpackChunkName: "team/ross-hiatt" */ "@/views/team/ross-hiatt.vue"),
  // },
  // {
  //   path: "/team/matthew-lozow",
  //   name: "matthew-lozow",
  //   component: () =>
  //     import(/* webpackChunkName: "team/matthew-lozow" */ "@/views/team/matthew-lozow.vue"),
  // },
  // {
  //   path: "/team/andrew-liau",
  //   name: "andrew-liau",
  //   component: () =>
  //     import(/* webpackChunkName: "team/andrew-liau" */ "@/views/team/andrew-liau.vue"),
  // },
  // {
  //   path: "/team/matthew-kretzman",
  //   name: "matthew-kretzman",
  //   component: () =>
  //     import(/* webpackChunkName: "team/matthew-kretzman" */ "@/views/team/matthew-kretzman.vue"),
  // },
  // {
  //   path: "/team/david-hirschberg",
  //   name: "david-hirschberg",
  //   component: () =>
  //     import(/* webpackChunkName: "team/david-hirschberg" */ "@/views/team/david-hirschberg.vue"),
  // },
  // {
  //   path: "/team/markus-noe-nordberg",
  //   name: "markus-noe-nordberg",
  //   component: () =>
  //     import(/* webpackChunkName: "team/markus-noe-nordberg" */ "@/views/team/markus-noe-nordberg.vue"),
  // },
  // {
  //   path: "/team/pascal-meysson",
  //   name: "pascal-meysson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/pascal-meysson" */ "@/views/team/pascal-meysson.vue"),
  // },
  // {
  //   path: "/team/michael-mestel",
  //   name: "michael-mestel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/michael-mestel" */ "@/views/team/michael-mestel.vue"),
  // },
  // {
  //   path: "/team/fernando-marques-oliveira",
  //   name: "fernando-marques-oliveira",
  //   component: () =>
  //     import(/* webpackChunkName: "team/fernando-marques-oliveira" */ "@/views/team/fernando-marques-oliveira.vue"),
  // },
  // {
  //   path: "/team/bruce-robertson",
  //   name: "bruce-robertson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/bruce-robertson" */ "@/views/team/bruce-robertson.vue"),
  // },
  // {
  //   path: "/team/duncan-priston",
  //   name: "duncan-priston",
  //   component: () =>
  //     import(/* webpackChunkName: "team/duncan-priston" */ "@/views/team/duncan-priston.vue"),
  // },
  // {
  //   path: "/team/keval-patel",
  //   name: "keval-patel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/keval-patel" */ "@/views/team/keval-patel.vue"),
  // },
  // {
  //   path: "/team/bruce-robertson",
  //   name: "bruce-robertson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/bruce-robertson" */ "@/views/team/bruce-robertson.vue"),
  // },
  // {
  //   path: "/team/edward-pallesen",
  //   name: "edward-pallesen",
  //   component: () =>
  //     import(/* webpackChunkName: "team/edward-pallesen" */ "@/views/team/edward-pallesen.vue"),
  // },
  // {
  //   path: "/team/richard-stokes",
  //   name: "richard-stokes",
  //   component: () =>
  //     import(/* webpackChunkName: "team/richard-stokes" */ "@/views/team/richard-stokes.vue"),
  // },
  // {
  //   path: "/team/andrew-scotland",
  //   name: "andrew-scotland",
  //   component: () =>
  //     import(/* webpackChunkName: "team/andrew-scotland" */ "@/views/team/andrew-scotland.vue"),
  // },
  // {
  //   path: "/team/steven-schwartz",
  //   name: "steven-schwartz",
  //   component: () =>
  //     import(/* webpackChunkName: "team/steven-schwartz" */ "@/views/team/steven-schwartz.vue"),
  // },
  // {
  //   path: "/team/adam-schimel",
  //   name: "adam-schimel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/adam-schimel" */ "@/views/team/adam-schimel.vue"),
  // },
  // {
  //   path: "/team/alex-zisson",
  //   name: "alex-zisson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/alex-zisson" */ "@/views/team/alex-zisson.vue"),
  // },
  // {
  //   path: "/team/jeff-zanarini",
  //   name: "jeff-zanarini",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jeff-zanarini" */ "@/views/team/jeff-zanarini.vue"),
  // },
  // {
  //   path: "/team/ira-weidhorn",
  //   name: "ira-weidhorn",
  //   component: () =>
  //     import(/* webpackChunkName: "team/ira-weidhorn" */ "@/views/team/ira-weidhorn.vue"),
  // },
  // {
  //   path: "/team/jaime-bergel",
  //   name: "jaime-bergel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jaime-bergel" */ "@/views/team/jaime-bergel.vue"),
  // },
  // {
  //   path: "/team/adam-belfer",
  //   name: "adam-belfer",
  //   component: () =>
  //     import(/* webpackChunkName: "team/adam-belfer" */ "@/views/team/adam-belfer.vue"),
  // },
  // {
  //   path: "/team/daniel-rosenthal-ayash",
  //   name: "daniel-rosenthal-ayash",
  //   component: () =>
  //     import(/* webpackChunkName: "team/daniel-rosenthal-ayash" */ "@/views/team/daniel-rosenthal-ayash.vue"),
  // },
  // {
  //   path: "/team/alejandra-arguello",
  //   name: "alejandra-arguello",
  //   component: () =>
  //     import(/* webpackChunkName: "team/alejandra-arguello" */ "@/views/team/alejandra-arguello.vue"),
  // },
  // {
  //   path: "/team/kenneth-borton",
  //   name: "kenneth-borton",
  //   component: () =>
  //     import(/* webpackChunkName: "team/kenneth-borton" */ "@/views/team/kenneth-borton.vue"),
  // },
  // {
  //   path: "/team/tobias-borkowski",
  //   name: "tobias-borkowski",
  //   component: () =>
  //     import(/* webpackChunkName: "team/tobias-borkowski" */ "@/views/team/tobias-borkowski.vue"),
  // },
  // {
  //   path: "/team/ignacio-blasco-lafita",
  //   name: "ignacio-blasco-lafita",
  //   component: () =>
  //     import(/* webpackChunkName: "team/ignacio-blasco-lafita" */ "@/views/team/ignacio-blasco-lafita.vue"),
  // },
  // {
  //   path: "/team/mark-bernier",
  //   name: "mark-bernier",
  //   component: () =>
  //     import(/* webpackChunkName: "team/mark-bernier" */ "@/views/team/mark-bernier.vue"),
  // },
  // {
  //   path: "/team/rodrigo-azevedo",
  //   name: "rodrigo-azevedo",
  //   component: () =>
  //     import(/* webpackChunkName: "team/rodrigo-azevedo" */ "@/views/team/rodrigo-azevedo.vue"),
  // },
  // {
  //   path: "/team/luciano-anzanello",
  //   name: "luciano-anzanello",
  //   component: () =>
  //     import(/* webpackChunkName: "team/luciano-anzanello" */ "@/views/team/luciano-anzanello.vue"),
  // },
  // {
  //   path: "/team/joao-almeida",
  //   name: "joao-almeida",
  //   component: () =>
  //     import(/* webpackChunkName: "team/joao-almeida" */ "@/views/team/joao-almeida.vue"),
  // },
  // {
  //   path: "/team/maximilien-albou",
  //   name: "maximilien-albou",
  //   component: () =>
  //     import(/* webpackChunkName: "team/maximilien-albou" */ "@/views/team/maximilien-albou.vue"),
  // },
  // {
  //   path: "/team/bernice-berschader",
  //   name: "bernice-berschader",
  //   component: () =>
  //     import(/* webpackChunkName: "team/bernice-berschader" */ "@/views/team/bernice-berschader.vue"),
  // },
  // {
  //   path: "/team/stephanie-bednar",
  //   name: "stephanie-bednar",
  //   component: () =>
  //     import(/* webpackChunkName: "team/stephanie-bednar" */ "@/views/team/stephanie-bednar.vue"),
  // },
  // {
  //   path: "/team/antoine-baudesson",
  //   name: "antoine-baudesson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/antoine-baudesson" */ "@/views/team/antoine-baudesson.vue"),
  // },
  // {
  //   path: "/team/crosby-baker",
  //   name: "crosby-baker",
  //   component: () =>
  //     import(/* webpackChunkName: "team/crosby-baker" */ "@/views/team/crosby-baker.vue"),
  // },
  // {
  //   path: "/team/dominik-boehm",
  //   name: "dominik-boehm",
  //   component: () =>
  //     import(/* webpackChunkName: "team/dominik-boehm" */ "@/views/team/dominik-boehm.vue"),
  // },
  // {
  //   path: "/support",
  //   name: "support",
  //   component: () => import(/* webpackChunkName: "support" */ "../views/Support.vue"),
  // },
  // {
  //   path: "/services",
  //   name: "services",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "services" */ '@/views/services.vue'
  //     )
  // },
  // {
  //   path: "/achievement",
  //   name: "achievement",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "achievement" */ '@/views/achievement.vue'
  //     )
  // },
  // {
  //   path: "/advisors",
  //   name: "advisors",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "advisors" */ '@/views/advisors.vue'
  //     )
  // },
  // {
  //   path: "/aboutus",
  //   name: "aboutus",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "@/views/aboutus.vue"),
  // },
  {
    path: '/:pathMatch(.*)',
    //访问主页的时候 重定向到index页面
    redirect: '/',
  },
];
const  scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { left: 0, top:0 };
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
