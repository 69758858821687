<template>
  <footer id="footer">
    <div class="wrap">
      <a href="/"><img class="logo"
          src="@/assets/footer-logo.png"
          alt="Vistr logo" /></a>

      <nav class="nav">
        <ul id="menu-header-navigation-1" class="menu">
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-9439"><a
              href="/">Home</a></li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-50"><router-link
              to="/about">About</router-link>
            <ul class="sub-menu">
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-51"><router-link
                  to="/about/team">Team</router-link></li>
            </ul>
          </li>
          <li
            class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-17 current_page_item menu-item-has-children menu-item-52">
            <router-link to="/companies" aria-current="page">Companies</router-link>
            <ul class="sub-menu">
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-53"><router-link
                  to="/companies/portfolio">Portfolio</router-link></li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-10114"><router-link
                  to="/companies/approach">Approach</router-link></li>
            </ul>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-55"><router-link
              to="/strategies">Strategies</router-link>
            <ul class="sub-menu">
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-56"><router-link
                  to="/strategies/private-equity">Private Equity</router-link></li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-59"><router-link
                to="/strategies/permanent-capital">Permanent Capital</router-link></li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-58"><router-link
                to="/strategies/credit">Credit</router-link></li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-57"><router-link
                  to="/strategies/public-equity">Public Equity</router-link></li>
            </ul>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-10920"><router-link
              to="/responsibility">Responsibility</router-link>
            <ul class="sub-menu">
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12495"><router-link
                  to="/responsibility/environmental-practices">Environmental
                  Practices</router-link></li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-62"><router-link
                  to="/responsibility/social-engagement">Social Engagement</router-link>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12496"><router-link
                  to="/responsibility/governance-strategy">Governance Strategy</router-link>
              </li>
            </ul>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-60"><router-link
              to="/insights">Insights</router-link></li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-61"><router-link
              to="/news">News</router-link>
            <ul class="sub-menu">
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15509"><router-link
                to="/news/press-releases">Press Releases</router-link></li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15510"><router-link
                  to="/news/news-updates">News &#038; Updates</router-link></li>
            </ul>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-63"><router-link
              to="/careers">Careers</router-link>
            <ul class="sub-menu">
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15511"><router-link
                  to="/careers/our-people">Our People</router-link></li>
            </ul>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-10029"><router-link
              to="/contact">Contact</router-link></li>
          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-105"><a target="_blank"
              rel="noopener" href="/#/login">Investor Login</a></li>
        </ul>
      </nav>

      <div class="side">
        <p><a target="_blank" href="/#/login" class="cta">Investor
            Login</a></p>
        <div class="social">
          <p>
            <a href="https://www.linkedin.com/company/vista-equity-partners/" class="social_icon linkedin"
              target="_blank"><img
                src="https://www.vistaequitypartners.com/wp-content/themes/vista_equity/images/Social-Icons_LinkedIn_Cyan.svg"
                alt="Connect with Vistr on LinkedIn" /></a>
            <a href="https://x.com/vista_equity" class="social_icon twitter" target="_blank"><img
                src="https://www.vistaequitypartners.com/wp-content/themes/vista_equity/images/Social-Icons_X_Cyan.svg"
                alt="Follow Vistr on X" /></a>
            <a href="https://www.facebook.com/VistaEquityPartners" class="social_icon facebook" target="_blank"><img
                src="https://www.vistaequitypartners.com/wp-content/themes/vista_equity/images/Social-Icons_Facebook_Cyan.svg"
                alt="Connect with Vistr on Facebook" /></a>
            <a href="https://www.instagram.com/vistaequitypartners/" class="social_icon instagram" target="_blank"><img
                src="https://www.vistaequitypartners.com/wp-content/themes/vista_equity/images/Social-Icons_Instagram_Cyan.svg"
                alt="Connect with Vistr on Instagram" /></a>
            <a href="https://www.youtube.com/channel/UC9cdOtpHph_JV5BAP-fH2bg" class="social_icon youtube"
              target="_blank"><img
                src="https://www.vistaequitypartners.com/wp-content/themes/vista_equity/images/Social-Icons_YouTube_Cyan.svg"
                alt="Subscribe to Vistr | YouTube" /></a>
          </p>
        </div>
        <p class="mailing-list">Connect: <a data-fancybox data-src="#mailingModal" href="javascript:;">Join Our Mailing
            List</a></p>
        <p class="mailing-list">Subscribe: <a target="_blank"
            href="https://www.linkedin.com/newsletters/7232412760901337088/">LinkedIn Newsletter</a></p>
      </div>

      <hr />

      <div class="cities">
        <div>
          <h3>Austin</h3>
          <p>
            <a href="https://maps.google.com/maps?q=401+Congress+Avenue,Suite+3100,Austin,+TX+78701">
              401 Congress Avenue<br />
              Suite 3100<br />
              Austin, TX 78701 </a><br />
            <a href="tel:512-730-2400">512.730.2400</a>
          </p>
        </div>
        <div>
          <h3>Chicago</h3>
          <p>
            <a
              href="https://maps.google.com/maps?q=2+Prudential+Plaza,180+North+Stetson+Avenue,Suite+4000,Chicago,+IL+60601">
              2 Prudential Plaza<br />
              180 North Stetson Avenue<br />
              Suite 4000<br />
              Chicago, IL 60601 </a><br />
            <a href="tel:312-229-9500">312.229.9500</a>
          </p>
        </div>
        <div>
          <h3>New York</h3>
          <p>
            <a href="https://maps.google.com/maps?q=50+Hudson+Yards,Floor+77,New+York,+NY+10001">
              50 Hudson Yards<br />
              Floor 77<br />
              New York, NY 10001 </a><br />
            <a href="tel:212-804-9100">212.804.9100</a>
          </p>
        </div>
        <div>
          <h3>San Francisco</h3>
          <p>
            <a href="https://maps.google.com/maps?q=4+Embarcadero+Center,20th+Floor,San+Francisco,+CA+94111">
              4 Embarcadero Center<br />
              20th Floor<br />
              San Francisco, CA 94111 </a><br />
            <a href="tel:415-765-6500">415.765.6500</a>
          </p>
        </div>
        <!--div>
          <h3>Oakland</h3>
          <p>
            <a href="https://maps.google.com/maps?q=">
                            </a><br />
                                      </p>
        </div-->
        <div>
          <h3>Hong Kong</h3>
          <p>
            <a href="https://maps.google.com/maps?q=Cheung+Kong+Center,Suite+1902,2+Queen’s+Road,Central,+Hong+Kong">
              Cheung Kong Center<br />
              Suite 1902<br />
              2 Queen’s Road<br />
              Central, Hong Kong </a><br />
          </p>
        </div>
      </div>

      <div class="copyright">
        <p>Copyright &copy; 2024 by Vistr Management, LLC</p>
        <router-link to="/privacy">Privacy</router-link>
        <router-link to="/disclosures">Disclosures</router-link>
        <router-link to="/responsible-investment-policy">Responsible Investment Policy</router-link>
        <router-link to="/faqs">FAQ</router-link>
      </div>

    </div>
  </footer>

  <div class="form-overlay" id="mailingModal" style="display: none;">
    <h2 class="headline">Join Our Mailing List</h2>
    <form id="mktoForm_1245"></form>
  </div>
</template>

<script setup>
      MktoForms2.loadForm("//info.vistaequitypartners.com", "839-JEW-563", 1245);
  // import { onMounted } from 'vue';

  //   onMounted(()=>{
  //     require('@/assets/js/common.js')
  //   })
</script>

<style lang="scss" scoped>
</style>